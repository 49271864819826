import * as React from 'react'
import Header from './Header'
import { GatsbyImage } from 'gatsby-plugin-image'

import './style.scss'

const Layout = ({ children, backgroundImage }) => {
    return (
        <>
            <div id='root'>
                <Header />
                <main>
                    {backgroundImage && (
                        <div className='background-image'>
                            <GatsbyImage
                                image={backgroundImage}
                                alt='page background'
                            />
                        </div>
                    )}
                    <div className='content-colwrap'>
                        <div className='content-padwrap'>
                            <div className='content'>{children}</div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default Layout
