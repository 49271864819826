import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import { Link, useStaticQuery, graphql } from 'gatsby'

export const orderMap = {
    1: 'About',
    2: 'CompaniesAndBusinesses',
    3: 'ProjectReferences',
    4: 'Media',
}

const SubMenuItem = ({
    menuUrl,
    URLslot: subMenuUrl,
    Title: title,
    toggleMenu,
}) => (
    <li>
        <Link onClick={toggleMenu} to={`/${menuUrl}/${subMenuUrl}`}>
            {title}
        </Link>
    </li>
)

const SubMenu = ({ menuUrl, child, toggleMenu }) => (
    <ul>
        {child
            .map(r => ({ ...r, menuUrl, key: r.id }))
            .map(r => (
                <SubMenuItem {...r} toggleMenu={toggleMenu} />
            ))}
    </ul>
)

const MenuItem = ({
    URLslot: menuUrl,
    Description: title,
    child,
    toggleMenu,
}) => {
    const [active, setActive] = useState(false)
    return (
        <li
            className={`item ${active ? 'active' : ''}`}
            onClick={() => {
                setActive(r => !r)
                if (!child && toggleMenu) {
                    toggleMenu()
                }
            }}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <Link to={child ? '#' : '/' + menuUrl}>{title}</Link>
            {child ? (
                <SubMenu
                    menuUrl={menuUrl}
                    child={child}
                    toggleMenu={toggleMenu}
                />
            ) : null}
        </li>
    )
}

const Header = () => {
    const [active, setActive] = useState(false)
    const toggleMenu = () => setActive(s => !s)
    const data = useStaticQuery(
        graphql`
            query SiteHeaderQuery {
                allStrapiMenuItems {
                    nodes {
                        order
                        id
                        URLslot
                        Description
                    }
                }
                allStrapiAboutPages {
                    nodes {
                        order
                        id
                        Title
                        URLslot
                    }
                }
                allStrapiCompaniesAndBusinessesPages {
                    nodes {
                        order
                        id
                        Title
                        URLslot
                    }
                }
                allStrapiProjectReferencesPages {
                    nodes {
                        order
                        id
                        Title
                        URLslot
                    }
                }
                allStrapiMediaPages {
                    nodes {
                        order
                        id
                        Title
                        URLslot
                    }
                }
            }
        `
    )
    const menuItems = data.allStrapiMenuItems.nodes
        .sort((a, b) => a.order - b.order)
        .map(r => {
            const toReturn = { ...r, key: r.id }
            const queryKey = `allStrapi${orderMap[r.order]}Pages`
            toReturn.child = Array.isArray(data[queryKey]?.nodes)
                ? data[queryKey]?.nodes
                      .filter(r => Number.isFinite(r.order))
                      .sort((a, b) => a.order - b.order)
                : null
            return toReturn
        })
    const headerClassName = active ? 'shown' : ''
    return (
        <>
            <div className='topnav'>
                <Link to='/' className='logo'>
                    <img src='/images/atw_logo.png' alt='Logo' />
                </Link>
                <button onClick={toggleMenu}>
                    <FontAwesomeIcon icon={active ? faTimes : faBars} />
                </button>
            </div>
            <header className={headerClassName}>
                <div className='header-wrapper'>
                    <div className='top-wrapper'>
                        <div className='top-wrapper-bg' />
                    </div>
                    <div className='center-wrapper'>
                        <div className='logo'>
                            <Link to='/' className='logo'>
                                <img src='/images/atw_logo.png' alt='Logo' />
                            </Link>
                        </div>
                        <ul className='menu-items'>
                            {menuItems.map(r => (
                                <MenuItem {...r} toggleMenu={toggleMenu} />
                            ))}
                        </ul>
                    </div>
                    <div className='foot-wrapper'>
                        <div>
                            <p>&copy;{new Date().getFullYear()} ATW Group</p>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
